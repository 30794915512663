import Axios from "axios"
import { getIdToken, getProfile, isAuthenticated } from "./auth"

function getBody(c) {
  return c.data
}

class APIClass {
  profile = null

  init(endpoint = null) {
    if (!endpoint) {
      endpoint = process.env.API_ENDPOINT
    }

    const opts = { baseURL: endpoint }
    if (isAuthenticated()) {
      opts.headers = {
        Authorization: `Bearer ${getIdToken()}`,
      }
    }

    this.client = Axios.create(opts)
  }

  getProfile() {
    if (this.profile) {
      return Promise.resolve(this.profile)
    }

    return this.client
      .get("/api/v1/profile")
      .then(getBody)
      .then(p => ({ ...getProfile(), ...p }))
      .then(p => {
        this.profile = p
        return p
      })
  }
}

const API = new APIClass()

export default API
