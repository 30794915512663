import auth0 from "auth0-js"
import { navigate } from "gatsby"
import API from "./api"

const isBrowser = typeof window !== "undefined"

const auth = isBrowser
  ? new auth0.WebAuth({
      domain: process.env.AUTH0_DOMAIN,
      clientID: process.env.AUTH0_CLIENTID,
      redirectUri: process.env.AUTH0_CALLBACK,
      responseType: "token id_token",
      scope: "openid email picture",
    })
  : {}

const tokens = {
  accessToken: false,
  idToken: false,
  expiresAt: false,
}

let user = {}

export const isAuthenticated = () => {
  if (!isBrowser) {
    return
  }

  return Boolean(window.localStorage.getItem("isLoggedIn"))
}

export const login = (toLocation = null) => {
  if (!isBrowser) {
    return
  }

  auth.authorize(toLocation ? { state: toLocation } : undefined)
}

const setSession = (cb = () => {}) => (err, authResult) => {
  if (!isBrowser) {
    return
  }

  if (err) {
    console.error("setSession", err)
    navigate("/")
    cb(err)
    return
  }

  if (authResult && authResult.accessToken && authResult.idToken) {
    let expiresAt = authResult.expiresIn * 1000 + new Date().getTime()
    tokens.accessToken = authResult.accessToken
    tokens.idToken = authResult.idToken
    tokens.expiresAt = expiresAt
    user = authResult.idTokenPayload
    window.localStorage.setItem("isLoggedIn", "true")

    API.init()
    API.getProfile().then(
      () => cb(null, user),
      (err) => cb(err)
    )
  }
}

export const silentAuth = (callback) => {
  if (!isAuthenticated()) return callback()
  auth.checkSession({}, setSession(callback))
}

export const handleAuthentication = (cb) => {
  if (!isBrowser) {
    return
  }

  auth.parseHash(setSession(cb))
}

export const getProfile = () => {
  return user
}

export function getAccessToken() {
  return tokens.accessToken
}

export function getIdToken() {
  return tokens.idToken
}

export const logout = () => {
  if (!isBrowser) {
    return
  }

  window.localStorage.setItem("isLoggedIn", false)
  auth.logout({ returnTo: process.env.AUTH0_LOGOUT_HOME })
}
