module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-KJM3KSC","includeInDevelopment":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"mix:analog","short_name":"mix:analog","start_url":"/","background_color":"#c3c1c6","theme_color":"#4829b2","display":"standalone","icon":"/opt/build/repo/src/i/favicon-32x32.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"cc5b498d00a122d9d1627f5d4f3966d5"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
