/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import React from "react"
import { silentAuth } from "./src/utils/auth"

const SessionCheck = ({ children }) => {
  const [loading, setLoading] = React.useState(false)

  const handleCheckSession = () => {
    setLoading(false)
  }

  React.useEffect(() => {
    silentAuth(handleCheckSession)
  }, [])

  return !loading && <>{children}</>
}

// TODO: remove this component and implement in SessionCheck if possible
export const wrapRootElement = ({ element }) => {
  return <SessionCheck>{element}</SessionCheck>
}
